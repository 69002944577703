const env = require("../../../env")
// 用户中心url
const url_usercenter = env[process.env.environment].VUE_APP_PERSONURL
const dialogModules = [
  {
    name: '商品发布',
    img: require('@/assets/img/home/new1/goods_issue_icon.png'),
    path:`${url_usercenter}/addCommodity`,
    pathAfter: 'addCommodity',
  },
  {
    name: "询价发布",
    img: require("@/assets/img/home/new1/enquiry_issue_icon.png"),
    path: `${url_usercenter}/SaveInquiryPrice`,
    pathAfter: `SaveInquiryPrice` //OurInquiryPriceList
  },
  {
    name: '招标发布',
    img: require('@/assets/img/home/new1/tend_issue_icon.png'),
    path:`${url_usercenter}/tenderingRelease`,
    pathAfter: 'NewCompanyAdd',
  },
  {
    name: "竞价发布",
    img: require("@/assets/img/home/new1/bidding_issue_icon.png"),
    path: `${url_usercenter}/CompeteBiddingAdd`,
    pathAfter: "CompeteBiddingAdd"
  },
  {
    name: '集采发布',
    img: require('@/assets/img/home/new1/jicai_issue_icon.png'),
    path: `${url_usercenter}/createJicai`,
    pathAfter: 'createJicai',
  },
  {
    name: '预算发布',
    img: require('@/assets/img/home/new1/ysfb.png'), // icon
    path: `${url_usercenter}/commodityDemand`,
    pathAfter: 'commodityDemand',
  },
  {
    name: '拍卖发布',
    img: require('@/assets/img/home/new1/pmfb.png'), // icon
    path: `${url_usercenter}/publishAuction`,
    pathAfter: 'publishAuction',
  },
  {
    name: '商品需求',
    img: require('@/assets/img/home/new1/spxq.png'), // icon
    path: `${url_usercenter}/OurInquiryPriceListNeed`,
    pathAfter: 'OurInquiryPriceListNeed',
  },
  {
    name: '求租发布',
    img: require('@/assets/img/home/new1/wanted_issue_icon.png'),
    path:`${url_usercenter}/addneedsinfo?categoryType=0`,
    pathAfter: 'machineNeedsList'
  },
  {
    name: '机械发布',
    img: require('@/assets/img/home/new1/mechanical_issue_icon.png'),
    path:`${url_usercenter}/myRentAll`,
    pathAfter: 'MyRent',
  },
  {
    name: '设备发布',
    img: require('@/assets/img/home/new1/equipment_issue_icon.png'),
    path:`${url_usercenter}/myRentAll`,
    pathAfter: 'equipmentRental',
  },
  {
    name: "车辆发布",
    img: require("@/assets/img/home/new1/vehicle_issue_icon.png"),
    path: `${url_usercenter}/myRentAll`,
    pathAfter: "carsrentinfolist"
  },
  {
    name: "房产发布",
    img: require("@/assets/img/home/new1/homeproperty_issue_icon.png"),
    path: `${url_usercenter}/myRentAll`,
    pathAfter: "estateRent"
  },
  {
    name: '工队发布',
    img: require('@/assets/img/home/new1/team_issue_icon.png'),
    path:`${url_usercenter}/teamEdit/:teamType=0:`,
    pathAfter: 'teamInfoEdit',
  },
  {
    name: '班组发布',
    img: require('@/assets/img/home/new1/bzfb.png'), //icon
    path:`${url_usercenter}/teamInfoEdit?teamType=1`,
    pathAfter: 'teamInfoEdit',
  },
  {
    name: '班组找活',
    img: require('@/assets/img/home/new1/bzzh.png'), //icon
    path:`${url_usercenter}/teamFindWork`,
    pathAfter: 'teamFindWork',
  },
  {
    name: '用工发布',
    img: require('@/assets/img/home/new1/ygfb.png'),
    path:`${url_usercenter}/flexWorkerEdit`,
    pathAfter: 'flexWorkerEdit' // 'flexWorkerList'
  },
  {
    name: '工程发布',
    img: require('@/assets/img/home/new1/project_issue_icon.png'),
    path:`${url_usercenter}/teamInfoEdit?teamType=1`,
    pathAfter: 'teamListManage',
  },
  {
    name: '承运发布',
    img: require('@/assets/img/home/new1/carriage_issue_icon.png'),
    path:`${url_usercenter}/wlglPublishAccept`,
    pathAfter: 'wlglPublishAccept',
  },
  {
    name: '货运发布',
    img: require('@/assets/img/home/new1/freight_issue_icon.png'),
    path:`${url_usercenter}/publishNormalGoods`,
    pathAfter: 'publishNormalGoods', // goodsManageList
  },
  {
    name: '商标转让发布',
    img: require('@/assets/img/home/new1/brand_issue_icon.png'),
    path:`${url_usercenter}/brandPublic`,
    pathAfter: 'brandPublic' //'brandList'
  },
  {
    name: '版权转让发布',
    img: require('@/assets/img/home/new1/copyright_issue_icon.png'),
    path:`${url_usercenter}/copyRightPublic`,
    pathAfter: 'copyRightPublic' // 'copyRightList'
  },
  {
    name: '专利转让发布',
    img: require('@/assets/img/home/new1/patent_issue_icon.png'),
    path:`${url_usercenter}/patentPublic`,
    pathAfter: 'patentPublic' // patentList
  },
  {
    name: '待办需求',
    img: require('@/assets/img/home/new1/xqfb.png'),
    path:`${url_usercenter}/publishDemand`,
    pathAfter: 'publishDemand' // companyList
  },
  {
    name: '待办服务',
    img: require('@/assets/img/home/new1/fwfb.png'),
    path:`${url_usercenter}/modifyServe`,
    pathAfter: 'modifyServe' // assetList
  }
]
export default dialogModules
