//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiKnowledge } from "@/api/apiKnowledge";
import { apiHome } from "@/api/apiHome";
var env = require("../../../../env");
import { imageCutting } from "@/util/util.js";
import comSmallTBSke from "../components/skeComponents/comSmallTBSke.vue";
export default {
  name: "zsfx",
  data() {
    return {
      currentMenuIndex: 0,
      childType: [],
      videoStyle: {},
      selectTypeNo: "",
      selectPage: 1,
      videoSrc: "",
      isShowVideoDialog: false,
      videoList: [],
      load: true,
      isClose: false,
      isMore: false,
      isLoadData: false,
      defaultUserImg: require("@/assets/user_default_icon.png"),
      isEmpty: false
    };
  },
  components: {
    "no-data": () => import("./noData"),
    CommonTag: () => import("./commonTag"),
    FooterLoad: () => import("@/components/FooterLoad/index.vue"),
    comSmallTBSke
  },
  computed: {
    isLogin: function() {
      return this.$store.state.loginType;
    }
  },
  watch: {
    isLogin: {
      handler: function(newVal, oldVal) {
        // this.getVideoList(true);
      }
    }
  },
  created() {
    this.flodItem();
  },
  methods: {
    imageCutting: imageCutting,
    /**@name 收起 */
    flodItem() {
      this.selectPage = 1;
      this.isClose = false;
      this.isMore = false;
      this.videoList = [];
      this.getList();
    },
    /**@name 更多 */
    loadingMore() {
      if (this.isLoadData) {
        this.selectPage++;
        this.isClose = true;
        this.getList();
      }
    },
    async getList() {
      if (!this.childType.length) {
        await this.getKnowledgeList();
        this.selectType();
        return;
      }
      this.selectType();
    },
    changeTag(index, name) {
      this.currentMenuIndex = index;
      const data = this.childType.find(item => item.name === name);
      this.selectTypeNo = data.no || "";
      this.flodItem();
    },
    async selectType() {
      this.load = true;
      const parmas = {
        type: this.selectTypeNo,
        page: this.selectPage,
        limit: 12
      };
      const res = await apiKnowledge.getvodList(parmas);
      const { data, msg, code } = res.data;
      if (+code === 200) {
        const list = data.records || [];
        this.isLoadData = data.records.length === parmas.limit;
        this.videoList = this.videoList.concat(list);
        this.isMore = this.selectPage < data.pages;
        this.isEmpty = this.videoList.length == 0;
        this.load = false;
        return;
      }
      this.load = false;
    },
    /**@name 获取所有的分类 */
    async getKnowledgeList() {
      const res = await apiKnowledge.getKnowledgeList();
      const { data, msg, code } = res.data;
      if (+code === 200) {
        let list = data || [];
        list = list.filter(item => {
          if (item.name === "全部") {
          } else {
            return {
              name: item.name,
              no: item.no
            };
          }
        });
        let lists = [{ name: "全部", no: "" }].concat(list);
        this.selectTypeNo = lists[0].no;
        this.childType = lists;
      }
    },
    // 跳转自定义标签列表
    go2tagList(data) {
      const knowledge_url =
        env[process.env.environment].VUE_APP_KNOWLEDGEsHARING;
      const { labelName, labelNo } = data;
      window.open(
        `${knowledge_url}/tagList?labelNo=${labelNo}&labelName=${labelName}`
      );
    },
    // 跳转详情
    go2detail(data) {
      const knowledge_url =
        env[process.env.environment].VUE_APP_KNOWLEDGEsHARING;
      const { id, typeName } = data;
      console.log("data", data);
      window.open(`${knowledge_url}/detailPop?videoId=${id}`);
    },
    // 播放视频
    playVideo(item) {
      let self = this;
      apiKnowledge.getPlayUrl(item.videoId).then(res => {
        self.isLoading = false;
        if (res.data.code === 200) {
          if (res.data.data.playInfoList.length) {
            let obj = res.data.data.playInfoList.find(item => {
              return item.format === "mp4";
            });
            self.videoSrc = obj.playURL;
          }
          this.isShowVideoDialog = true;
        }
      });
    },
    //关闭视频
    closeVideo() {
      this.$refs.videoDom.pause();
      // this.getVideoList(false);
    },
    // 关注
    followUser(data) {
      let user = this.$store.state;
      const { createUser, isFollow } = data;
      if (!user.token) {
        // this.$store.commit("SET_LOGINDIALOG", true);
        let data = {
          callBack: function() {
            this.$store.dispatch("setUser");
            // this.pushThis();
          }.bind(this)
        };
        this.$_login(data);
      } else {
        console.log("123");
        apiKnowledge
          .follow(createUser, data.user === createUser ? "" : data.user)
          .then(res => {
            if (res.data && res.data.code === 200) {
              isFollow
                ? this.$_toast({ type: "info", msg: "取消关注成功" })
                : this.$_toast({ type: "success", msg: "关注成功" });
              this.flodItem();
            } else {
              let data = {
                callBack: function() {
                  this.$store.dispatch("setUser");
                  // this.pushThis();
                }.bind(this)
              };
              this.$_login(data);
            }
          });
      }
    },
    more() {
      window.open(
        `${env[process.env.environment].VUE_APP_KNOWLEDGEsHARING}/course/list`
      );
    }
  }
};
